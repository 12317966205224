/* Sticky Footer Classes */

html,
body {
  height: 99%;
}

#page-content {
  flex: 1 0 auto;
}

#sticky-footer {
  flex-shrink: none;
}

/* Other Classes for Page Styling */

body {
  background: #007bff;
  background: linear-gradient(to right, #0062E6, #33AEFF);
  /* min-height: 100vh; */
}
.profile-header {
  transform: translateY(5rem);
}

.floating {  
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 15px); }
  to   { transform: translate(0, -0px); }    
}